import React, { useState } from "react"
import { navigate } from "gatsby"
import vLogo from "../../images/VLogo.png"

import {
  Menu,
  Container,
  Segment,
  Grid,
  Image
} from "semantic-ui-react"

const DesktopContainer = ({ Media, children, location }) => {
  const [homeActive, setHomeActive] = useState(location.pathname === "/");
  const [animalsActive, setAnimalsActive] = useState(location.pathname.indexOf("/animals") !== -1);
  const [environmentActive, setEnvironmentActive] = useState(location.pathname.indexOf("/environment") !== -1);
  const [healthActive, setHealthActive] = useState(location.pathname.indexOf("/health") !== -1);
  const [productsActive, setProductsActive] = useState(location.pathname.indexOf("/products") !== -1);
  const [otherActive, setOtherActive] = useState(location.pathname.indexOf("/other") !== -1);

  const disableAllExcept = (fn) => {
    setHomeActive(fn === setHomeActive);
    setAnimalsActive(fn === setAnimalsActive);
    setEnvironmentActive(fn === setEnvironmentActive);
    setHealthActive(fn === setHealthActive);
    setProductsActive(fn === setProductsActive);
    setOtherActive(fn === setOtherActive);
  }

  const nav = (category) => {
    if (category === "home") { return navigate("/"); }
    navigate(`/${category}`);
  }

  return (
    <Media greaterThan="mobile">
      <Segment vertical inverted>
        <Grid celled="internally">
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Menu
                inverted
                pointing
                secondary
                size="large"
              >
                <Container>
                  <Menu.Item
                    active={homeActive}
                    fitted="vertically"
                    onClick={() => { disableAllExcept(setHomeActive); nav("home"); }}
                    style={{padding: 0, marginRight: 10}}
                  >
                    <Image src={vLogo} alt="Vegandata logo" width="50"/>
                  </Menu.Item>

                  <Menu.Item
                    active={animalsActive}
                    onClick={() => { disableAllExcept(setAnimalsActive); nav("animals"); }}
                  >
                    Animals
                  </Menu.Item>

                  <Menu.Item
                    active={environmentActive}
                    onClick={() => { disableAllExcept(setEnvironmentActive); nav("environment"); }}
                  >
                    Environment
                  </Menu.Item>

                  <Menu.Item
                    active={healthActive}
                    onClick={() => { disableAllExcept(setHealthActive); nav("health"); }}
                  >
                    Health
                  </Menu.Item>

                  <Menu.Item
                    active={productsActive}
                    onClick={() => { disableAllExcept(setProductsActive); nav("products"); }}
                  >
                    Vegan Products
                  </Menu.Item>

                  <Menu.Item
                    active={otherActive}
                    onClick={() => { disableAllExcept(setOtherActive); nav("other"); }}
                  >
                    Other
                    </Menu.Item>
                </Container>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {children}
    </Media>
  )
}

export default DesktopContainer;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animals-js": () => import("./../../../src/pages/animals.js" /* webpackChunkName: "component---src-pages-animals-js" */),
  "component---src-pages-environment-js": () => import("./../../../src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-visualizations-animals-animals-slaughtered-js": () => import("./../../../src/pages/visualizations/animals/animals-slaughtered.js" /* webpackChunkName: "component---src-pages-visualizations-animals-animals-slaughtered-js" */),
  "component---src-pages-visualizations-animals-sanctuaries-js": () => import("./../../../src/pages/visualizations/animals/sanctuaries.js" /* webpackChunkName: "component---src-pages-visualizations-animals-sanctuaries-js" */),
  "component---src-pages-visualizations-animals-shelter-animal-counts-js": () => import("./../../../src/pages/visualizations/animals/shelter-animal-counts.js" /* webpackChunkName: "component---src-pages-visualizations-animals-shelter-animal-counts-js" */),
  "component---src-pages-visualizations-animals-us-facilities-js": () => import("./../../../src/pages/visualizations/animals/us-facilities.js" /* webpackChunkName: "component---src-pages-visualizations-animals-us-facilities-js" */),
  "component---src-pages-visualizations-environment-material-sustainability-index-js": () => import("./../../../src/pages/visualizations/environment/material-sustainability-index.js" /* webpackChunkName: "component---src-pages-visualizations-environment-material-sustainability-index-js" */),
  "component---src-pages-visualizations-environment-water-footprint-js": () => import("./../../../src/pages/visualizations/environment/water-footprint.js" /* webpackChunkName: "component---src-pages-visualizations-environment-water-footprint-js" */),
  "component---src-pages-visualizations-health-cardiovascular-disease-markers-js": () => import("./../../../src/pages/visualizations/health/cardiovascular-disease-markers.js" /* webpackChunkName: "component---src-pages-visualizations-health-cardiovascular-disease-markers-js" */),
  "component---src-pages-visualizations-health-lactose-intolerance-js": () => import("./../../../src/pages/visualizations/health/lactose-intolerance.js" /* webpackChunkName: "component---src-pages-visualizations-health-lactose-intolerance-js" */),
  "component---src-pages-visualizations-other-documentaries-js": () => import("./../../../src/pages/visualizations/other/documentaries.js" /* webpackChunkName: "component---src-pages-visualizations-other-documentaries-js" */),
  "component---src-pages-visualizations-other-google-trends-js": () => import("./../../../src/pages/visualizations/other/google-trends.js" /* webpackChunkName: "component---src-pages-visualizations-other-google-trends-js" */),
  "component---src-pages-visualizations-other-public-companies-js": () => import("./../../../src/pages/visualizations/other/public-companies.js" /* webpackChunkName: "component---src-pages-visualizations-other-public-companies-js" */),
  "component---src-pages-visualizations-other-vegan-society-trademarks-js": () => import("./../../../src/pages/visualizations/other/vegan-society-trademarks.js" /* webpackChunkName: "component---src-pages-visualizations-other-vegan-society-trademarks-js" */)
}


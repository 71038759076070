import React, { useState } from "react"
import { navigate } from "gatsby"
import headerLogo from "../../images/HeaderLogo.png"

import {
    Sidebar,
    Menu,
    Container,
    Segment,
    Grid,
    Icon,
} from "semantic-ui-react"

const MobileContainer = ({ Media, children, location }) => {
    const [homeActive, setHomeActive] = useState(location.pathname === "/");
    const [animalsActive, setAnimalsActive] = useState(location.pathname.indexOf("/animals") !== -1);
    const [environmentActive, setEnvironmentActive] = useState(location.pathname.indexOf("/environment") !== -1);
    const [healthActive, setHealthActive] = useState(location.pathname.indexOf("/health") !== -1);
    const [productsActive, setProductsActive] = useState(location.pathname.indexOf("/products") !== -1);
    const [otherActive, setOtherActive] = useState(location.pathname.indexOf("/other") !== -1);

    const disableAllExcept = (fn) => {
        setHomeActive(fn === setHomeActive);
        setAnimalsActive(fn === setAnimalsActive);
        setEnvironmentActive(fn === setEnvironmentActive);
        setHealthActive(fn === setHealthActive);
        setProductsActive(fn === setProductsActive);
        setOtherActive(fn === setOtherActive);
    }

    const nav = (category) => {
        setSidebarOpened(false);
        if (category === "home") { return navigate("/"); }
        navigate(`/${category}`);
    }

    const [sidebarOpened, setSidebarOpened] = useState(false);
    const handleToggle = () => setSidebarOpened(true);

    return (
        <Media as={Sidebar.Pushable} at="mobile">
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation="overlay"
                    inverted
                    onHide={() => setSidebarOpened(false)}
                    vertical
                    visible={sidebarOpened}
                >
                    <Menu.Item
                        as="a"
                        active={homeActive}
                        onClick={() => { disableAllExcept(setHomeActive); nav("home"); }}
                    >
                        Home
                    </Menu.Item>

                    <Menu.Item
                        as="a"
                        active={animalsActive}
                        onClick={() => { disableAllExcept(setAnimalsActive); nav("animals"); }}
                    >
                        Animals
                    </Menu.Item>

                    <Menu.Item
                        as="a"
                        active={environmentActive}
                        onClick={() => { disableAllExcept(setEnvironmentActive); nav("environment"); }}
                    >
                        Environment
                    </Menu.Item>

                    <Menu.Item
                        as="a"
                        active={healthActive}
                        onClick={() => { disableAllExcept(setHealthActive); nav("health"); }}
                    >
                        Health
                    </Menu.Item>

                    <Menu.Item
                        as="a"
                        active={productsActive}
                        onClick={() => { disableAllExcept(setProductsActive); nav("products"); }}
                    >
                        Vegan Products
                    </Menu.Item>

                    <Menu.Item
                        as="a"
                        active={otherActive}
                        onClick={() => { disableAllExcept(setOtherActive); nav("other"); }}
                    >
                        Other
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment
                        inverted
                        textAlign="center"
                        style={{ minHeight: 35, padding: "1em 0em" }}
                        vertical
                    >
                        <Container>
                            <Grid divided='vertically'>
                                <Grid.Row verticalAlign="middle" textAlign="center">
                                    <Grid.Column width={2}>
                                        <Menu inverted pointing secondary size="large">
                                            <Menu.Item onClick={handleToggle}>
                                                <Icon name="sidebar" />
                                            </Menu.Item>
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column width={14}>
                                        <img src={headerLogo} alt="Vegandata logo"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    )
}

export default MobileContainer;

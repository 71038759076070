import React from "react"
import { createMedia } from "@artsy/fresnel"
import DesktopContainer from "./desktopContainer"
import MobileContainer from "./mobileContainer"
import PropTypes from "prop-types"

import "../../semantic/semantic.min.css"

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    }
});

const ResponsiveContainer = ({ children, location }) => {
    const childrenWithProps = React.Children.map(children, (child) =>
        React.cloneElement(child, { mobile: true })
    );
    return (
        /* Heads up!
         * For large applications it may not be best option to put all page into these containers at
         * they will be rendered twice for SSR.
         */
        <MediaContextProvider>
            <DesktopContainer Media={Media} location={location}>{children}</DesktopContainer>
            <MobileContainer Media={Media} location={location}>{childrenWithProps}</MobileContainer>
        </MediaContextProvider>
    );
}

const Layout = ({ children, location }) => {
    return (<>
        <ResponsiveContainer location={location}>
            {children}
        </ResponsiveContainer>
    </>);
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;

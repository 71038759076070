/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//https://flaviocopes.com/gatsby-load-external-js/


const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }
  export const onClientEntry = () => {
    window.onload = () => {
      addScript("https://unpkg.com/leaflet@1.7.1/dist/leaflet.js")
    }
  }